@font-face {
  font-family: 'proxima-nova';
  src: url('../public/assets/fonts/ProximaNova-Regular.woff2') format('woff2'),
    url('../public/assets/fonts/ProximaNova-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'proxima-nova';
  src: url('../public/assets/fonts/ProximaNova-RegularIt.woff2') format('woff2'),
    url('../public/assets/fonts/ProximaNova-RegularIt.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'proxima-nova';
  src: url('../public/assets/fonts/ProximaNova-Light.woff2') format('woff2'),
    url('../public/assets/fonts/ProximaNova-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'proxima-nova';
  src: url('../public/assets/fonts/ProximaNova-Semibold.woff2') format('woff2'),
    url('../public/assets/fonts/ProximaNova-Semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'proxima-nova';
  src: url('../public/assets/fonts/ProximaNova-SemiboldIt.woff2') format('woff2'),
    url('../public/assets/fonts/ProximaNova-SemiboldIt.woff') format('woff');
  font-weight: 600;
  font-style: italic;
}

body {
  margin: 0;
  font-family: 'proxima-nova', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* .App .HypMapGL-root {
  height: calc(100vh - 56px);
  max-height: calc(100vh - 56px);
} */
/* @media (min-width: 600px) {
  .App .HypMapGL-root {
    height: calc(100vh - 64px);
    max-height: calc(100vh - 64px);
  }
}
*/

.HypMapGL-root .mapboxgl-ctrl-top-right {
  top: 8px;
  right: 8px;
}
.HypScale-markerWrapper svg * {
  stroke: #fff;
  stroke-width: 1;
}

.HypScale-root .tick line,
.HypScale-root .domain {
  stroke: #999;
}
